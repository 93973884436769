import React, { useEffect, useState } from "react";
import { CopyRight, LeftContainer, SubTitle } from "../styles/Styles";
import axios from "axios";
import { jsonToHtml } from "@contentstack/json-rte-serializer";
import ReactHtmlParser from "react-html-parser";
import Chemaid from "../assests/chemaid.png";

export default function FormBanner() {
  const [welcomeData, setWelcomeData] = useState(null);

  useEffect(() => {
    const fetchWelcomeMsg = async () => {
      try {
        const endPoint =
          "https://api.contentstack.io/v3/content_types/welcome/entries/bltc83c1e872eb90a98";
        const headers = {
          api_key: "blt6f0f282d20bbe00b",
          authorization: "cs126192b9f72c7fcf7ec544c3",
        };
        const response = await axios.get(endPoint, { headers });
        setWelcomeData(response.data.entry);
      } catch (error) {
        console.log(error);
      }
    };
    fetchWelcomeMsg();
  }, []);

  return (
    <LeftContainer>
      <div>
        <div style={{ textAlign: "center" }}>
          <img
            alt="chemaid-logo"
            src={Chemaid}
            width={140}
            style={{ objectFit: "contain" }}
          />
        </div>
        {welcomeData && welcomeData.message && (
          <>
            {/* <Title>{welcomeData.title}</Title> */}
            <SubTitle>
              {ReactHtmlParser(jsonToHtml(welcomeData.message))}
            </SubTitle>
          </>
        )}
      </div>
      <CopyRight>
        2023 CHEM&#8226;AID<sup>TM</sup>&#9135; All rights reserved.
      </CopyRight>
    </LeftContainer>
  );
}
